import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditNoteIcon from '@mui/icons-material/EditNoteOutlined'
import { Icon } from '@mui/material';
import {cores} from '../cores/Cores';
import '../popUp/popUp.scss';
import './button.scss'

export const Button = ({ onClick, background, color, border, width, height, text, children, margin, borderRadius, className, disabled = false, ...props }) => {
    return (
        <button 
            type="button" 
            onClick={onClick} 
            style={{ background, color, border, width, margin, borderRadius, height, ...props}} 
            className={`button ${className} ${disabled?disabled:''}`}
            disabled= {disabled}
        >
            { text ? text : children }
        </button> 
    )
}

export const AddButton = ({ onClick }) => {
    return (
        <Button onClick={onClick} height={"35px"} width={"40px"}>
            +
        </Button>
    );
};

export const WorldSearchButton = ({ onClick }) => {
    return (
        <Button onClick={onClick} height={"35px"} width={"50px"}>
            <Icon  className="icon-update">travel_explore</Icon>
        </Button>
    );
};

export const LocationButton = ({ trigger, setTrigger }) => {
    const toggleVisibility = () => {
        setTrigger(prevState => !prevState);
    };

    return (
            <Button onClick={toggleVisibility}  border= '0px' background= 'none' margin= 'auto'>
                <LocationOnIcon style={{width: '60px', height: 'auto', position: 'relative', bottom: '20px'}} />
            </Button>
    );
};

export const CopyButton = ({ link, onClick }) =>{
    return(
        <Button onClick={onClick} border= '0px' background= 'none'>
            <CopyToClipboard text={ link }>
                <ContentCopyOutlinedIcon />
            </CopyToClipboard>
        </Button>
    ) 
}

export const PhoneButton = ({ link, onClick }) =>{
    return(
        <Button onClick={onClick} border= '0px' background= 'none' height='auto'>
            <CopyToClipboard text={ link }>
                <img src='outline_phone_black_24dp.png' alt="cel" width={20} height={20} />
            </CopyToClipboard>
        </Button>
    ) 
}

export const EditButton = ({ onClick }) => {
    return (
        <Button onClick={onClick}  border= '0px' background= 'none'>
            <ModeEditIcon />
        </Button>
    );
};

export const EditNoteButton = ({ onClick }) => {
    return (
        <Button onClick={onClick}  border= '0px' background= 'none'>
            <EditNoteIcon />
        </Button>
    );
};

export const EyeButton = ({ onClick }) => {
    return (
        <Button onClick={onClick}  border= '0px' background= 'none'>
            <RemoveRedEyeIcon />
        </Button>
    );
};
export default Button

