import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';


export default function DynamicChart( {data, setData, valorTotal } ) {
  const dataInner = Object.values(data).map(({ label, value, color})=> {
    return { label, value, color}
  })

  const series = [
    {
      innerRadius: 17,
      outerRadius: 45,
      id: 'series-1',
      data: dataInner,
      cx: 60,
      cy: 60
    },
    
    {
      innerRadius: 0,
      outerRadius: 15,
      id: 'all',
      data: [{label: 'Todos', value: valorTotal, color: '#a78d7d'}],
      cx: 60,
      cy: 60
    }
  ];

  return (
    <Stack>
      <Box>
        <PieChart
          width={120}
          height={120}
          series={series}
          slotProps={{
           legend: { hidden: true },
          }}
        />
      </Box>
    </Stack>
  );
}
