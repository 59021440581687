import React, { useState, useEffect } from 'react'
import DynamicChart from '../DynamicChart/DynamicChart';
import Text from '../../../../componentes/text/Text';
import './card.scss'

export const CardTotal = ({title, data, setData}) => {
    const valorTotal = Object.values(data).reduce((acc, { value }) => {
        return acc + parseInt(value);
    }, 0);

    const qtTotal = Object.values(data).reduce((acc, { qt }) => {
        return acc + parseInt(qt);
    }, 0);
    return (
        <div className='card-dash-extratos'>
            <Text type='h2' style={{textAlign: 'center'}}>Resumo</Text>
            <div style={{width: '110px', display: 'flex'}}>
                <DynamicChart data={data} setData={setData} valorTotal={valorTotal}/>
                <div style={{margin: 'auto'}}>
                    <Text type='h3' style={{fontWeight: '10'}}>Total</Text>
                    <span style={{ width:'60px', marginRight: '20px', fontSize: 'large', textAlign: 'right', color: '#555'}}>{qtTotal}</span>
                    <span style={{ flex: 1,  fontSize: 'large', textAlign: 'right', color: '#555'}}>{valorTotal && valorTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </div>

            { 
                <div className='row'>
                    <div className='row'>
                        <div className='column' style={{justifyContent: 'center'}}> 
                            {Object.values(data).map(({ label, value, color, qt})=> {
                                
                                return(
                                    <div key={label} style={{width: '250px'}}>
                                        <SquareBulletsList qt={qt} color={color} text={label} value={value} />
                                    </div>
                                )
                            })}
                            <div style={{width: '250px'}}>
                                
                            </div>
                        </div> 
                    </div>
                    
                </div>
            }
        </div>
    )
}

const SquareBulletsList = ({ qt, color, text, value }) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', padding: '5px 0' }}>
                <span 
                style={{ 
                    width: '8px', 
                    height: '8px', 
                    backgroundColor: color, 
                    display: 'inline-block', 
                    marginRight: '10px',
                }}>
                </span>
                <span style={{ textAlign: 'left', width:'70px', fontWeight: 'bold' }}>{text}</span>
                <span style={{ width:'60px', textAlign: 'right', color: '#555'}}>{qt}</span>
                <span style={{ flex: 1, textAlign: 'right', color: '#555'}}>{value && value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
            </div>
        )

  };
